//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  data() {
    return {
      activeMenu: ''
    }
  },
  methods: {
    scrubLink(link) {
      return link.replace('pages', '')
    },
    setActiveMenu(i) {
      this.activeMenu = i
      return false
    }
  },
  computed: {
    ...mapState(['activeUser', 'activeOrder', 'location', 'menus']),
    cartCount() {
      return this.activeOrder && this.activeOrder.products
        ? this.activeOrder.products.length
        : 0
    },
    navi() {
      return this.menus.main ? this.menus.main.content.links : []
    },
     handoffText() {
      if(!this.activeOrder) return `<strong class="font-bold">Pickup</strong> AT `
      switch (this.activeOrder.handoff) {
        case 'pickup':
          return `<strong class="font-bold">Pickup</strong> AT `
        case 'curbside':
          return `<strong class="font-bold mr-1">Curbside</strong> AT `
        default:
          return `<strong class="font-bold">Delivery</strong> TO `
      }
    }
  }
}
