//
//
//
//
//

export default {

    data() {
        return {
            sm: ''
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.checkSize()
        })
        window.addEventListener('resize', this.checkSize)
    },
    methods: {
        checkSize() {
            this.sm = (window.innerWidth < 640) ? 1 : 0
        }
    }
}
