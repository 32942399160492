import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=36e1dc5a&scoped=true&"
import script from "./SiteHeader.vue?vue&type=script&lang=js&"
export * from "./SiteHeader.vue?vue&type=script&lang=js&"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&id=36e1dc5a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e1dc5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/global/Logo.vue').default,LocationWidget: require('/opt/build/repo/components/global/LocationWidget.vue').default,IconShoppingBag: require('/opt/build/repo/components/icons/ShoppingBag.vue').default,MobileMenu: require('/opt/build/repo/components/global/MobileMenu.vue').default,Header: require('/opt/build/repo/components/global/Header.vue').default})
