//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'

export default {
    data() {
        return {
            showMobileMenu: false,
            isMenuVisible: true,
            lastScrollTop: 0,
            threshold: 15,
            hideLocationModule: false
        }
    },
    watch: {
        $route: function () {
            this.showMobileMenu = false

            if (this.$store.state.page && this.$store.state.page.content && this.$store.state.page.content.hide_location_module) {
                console.log('includes value-breakfast ')
                this.hideLocationModule = true
            } else {
                console.log('does not includes includes value-breakfast ')
                this.hideLocationModule = false
            }
        }
    },
    mounted() {

        // console.log('this.$store.state.page::: ', this.$store.state.page)


        if (process.browser) window.addEventListener('scroll', this.handleScroll, false)

        if (this.$store.state.page && this.$store.state.page.content && this.$store.state.page.content.hide_location_module) {
            // console.log('includes value-breakfast ')
            this.hideLocationModule = true
        } else {
            // console.log('does not includes includes value-breakfast ')
            this.hideLocationModule = false
        }

        // console.log('this.$route.name:', this.$route)
        // if (this.$route.path.includes('value-breakfast') || this.$route.path.includes('value-breakfast-specials') || this.$route.path.includes('buy-one-take-one') || this.$route.path.includes('ultimate-french-toast-platters')) {
        //     console.log('includes value-breakfast ')
        //     this.hideLocationModule = true
        // } else {
        //     console.log('does not includes includes value-breakfast ')
        //     this.hideLocationModule = false
        // }
    },
    destroyed() {
        if (process.browser) window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        scrubLink(link) {
            return link.replace('pages', '')
        },
        ...mapMutations(['setGlobalCart', 'setLocationSelector']),
        handleScroll() {
            const st = window.pageYOffset || document.documentElement.scrollTop
            this.isMenuVisible = st > this.lastScrollTop ? false : true
            this.lastScrollTop = st <= 0 ? 0 : st // **** For Mobile or negative scrolling
        }
    },
    computed: {
        ...mapState([
            'activeUser',
            'activeOrder',
            'menus',
            'location',
            'activeUser'
        ]),
        orderingPage() {
            return this.$route.name &&
                (this.$route.name.match(/^order/) ||
                    this.$route.name.match(/^menu/) ||
                    (this.location && !this.location.menu_id))
                ? true
                : false
        },
        cartCount() {
            return this.activeOrder && this.activeOrder.products
                ? this.activeOrder.products.length
                : 0
        },
        isMobile() {
            return this.$mq !== 'sm' && this.$mq !== 'md' && this.$mq !== 'lg'
        },
        isDevice() {
            return this.$mq !== 'sm' && this.$mq !== 'md' && this.$mq !== 'lg'
        },
        navi() {
            return this.menus.main ? this.menus.main.content.links : []
        }
    }
}
