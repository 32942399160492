//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';

export default {
 
  data() {
    return {
      showMobileMenu: false,
      activeMenu: '',
      // light: this.$store.state.page && this.$store.state.page.content ? this.$store.state.page.content.light_header : false,
    }
  },
  watch: {
    '$route': function() {
      this.showMobileMenu = false
    },
    '$store.state.page': function() {
        // let path = this.$route.path
        // if (path.startsWith('/locations/') || path.startsWith('/account')) {
        //   this.light = true
        // } else {
        //   this.light = this.$store.state.page && this.$store.state.page.content ? this.$store.state.page.content.light_header : false
        // }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    light() {
      return false
    ///  return this.$store.state.page && this.$store.state.page.content ? this.$store.state.page.content.light_header : false
    },
    user() {
      return this.$store.state.activeUser
    },
    cart() {
      return this.$store.state.activeOrder
    },
    menu() {
      return this.$store.state.menus.main ? this.$store.state.menus.main.content.links : []
    },
    orderingPage() {
      return this.$route.name && (this.$route.name.match(/^order/) || this.$route.name.match(/^menu/) || (this.loc && !this.loc.menu_id)) ? true : false
    },
    loc() {
      return this.$store.state.location
    },
    cartCount() {
      return this.cart && this.cart.products ? this.cart.products.length : 0
    }
    //  ...mapGetters([
    //   'cartCount' 
    // ])
  },
  methods: {
    scrubLink(link) {
      return link.replace('pages', '')
    },
    setActiveMenu(i) {
      this.activeMenu = i
      return false
    },
    handleScroll() {
      let header = document.querySelector('.btn.order')
      let scrollY = window.scrollY
      if (header) {
        if (scrollY > 126 && window.innerWidth > 845) {
          header.style.position = 'fixed'
          header.style.top = '0px'
          header.style.right = '0px'
        } else {
          header.removeAttribute('style')
        }
      }
    },
    showCart() {
      this.$store.commit('setGlobalCart', true)
    },
    findLocation() {
      this.$store.commit('setLocationSelector', true)
    }
  }
}
