//
//
//
//
//
//
//


export default {
    props: {
       light: {
        type: Boolean,
        default: false
      },
      filled: {
        type: Boolean,
        default: false
      },
      displayAmount: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      isMobile(){
        return this.$mq === 'sm'  || this.$mq === 'md'
      },
      cart() {
        return this.$store.state.activeOrder
      },
      cartCount() {
        return this.cart && this.cart.products ? this.cart.products.length : 0
      } 
    },
}
